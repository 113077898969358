import { withPrefix } from 'gatsby'
import { createGlobalStyle } from 'styled-components'

export const StyledGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Cebo Light';
    src: url(${withPrefix('/fonts/cebo-light.eot')});
    src: url(${withPrefix('/fonts/cebo-light.eot?#iefix')}) format('embedded-opentype'),
        url(${withPrefix('/fonts/cebo-light.svg#proxima-nova-regular')}) format('svg'),
        url(${withPrefix('/fonts/cebo-light.ttf')}) format('truetype'),
        url(${withPrefix('/fonts/cebo-light.woff')}) format('woff'),
        url(${withPrefix('/fonts/cebo-light.woff2')}) format('woff2');
  }

  @font-face {
    font-family: 'Proxima Nova Extrabold';
    src: url(${withPrefix('/fonts/proxima-nova-extrabold.eot')});
    src: url(${withPrefix('/fonts/proxima-nova-extrabold.eot?#iefix')}) format('embedded-opentype'),
        url(${withPrefix('/fonts/proxima-nova-extrabold.svg#proxima-nova-extrabold')}) format('svg'),
        url(${withPrefix('/fonts/proxima-nova-extrabold.ttf')}) format('truetype'),
        url(${withPrefix('/fonts/proxima-nova-extrabold.woff')}) format('woff'),
        url(${withPrefix('/fonts/proxima-nova-extrabold.woff2')}) format('woff2');
  }
  @font-face {
    font-family: 'Proxima Nova Regular';
    src: url(${withPrefix('/fonts/proxima-nova-regular.eot')});
    src: url(${withPrefix('/fonts/proxima-nova-regular.eot?#iefix')}) format('embedded-opentype'),
        url(${withPrefix('/fonts/proxima-nova-regular.svg#proxima-nova-regular')}) format('svg'),
        url(${withPrefix('/fonts/proxima-nova-regular.ttf')}) format('truetype'),
        url(${withPrefix('/fonts/proxima-nova-regular.woff')}) format('woff'),
        url(${withPrefix('/fonts/proxima-nova-regular.woff2')}) format('woff2');
  }
  @font-face {
    font-family: 'Proxima Nova Light Italic';
    src: url(${withPrefix('/fonts/proxima-nova-light-italic.eot')});
    src: url(${withPrefix('/fonts/proxima-nova-light-italic.eot?#iefix')}) format('embedded-opentype'),
        url(${withPrefix('/fonts/proxima-nova-light-italic.svg#proxima-nova-light-italic')}) format('svg'),
        url(${withPrefix('/fonts/proxima-nova-light-italic.ttf')}) format('truetype'),
        url(${withPrefix('/fonts/proxima-nova-light-italic.woff')}) format('woff'),
        url(${withPrefix('/fonts/proxima-nova-light-italic.woff2')}) format('woff2');
  }
  @font-face {
    font-family: 'Proxima Nova Extrabold Italic';
    src: url(${withPrefix('/fonts/Proxima-Nova-Xbold-It.otf')});
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: normal;
    margin: 0;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    letter-spacing: normal;
    line-height: 1;
    margin: 0;
    font-family: 'Proxima Nova Regular', sans-serif;
    overflow-x: hidden;
  }

  a {
    color: #4a4a4a;
    text-decoration: none;
  }

  button {
    background-color: transparent;
    border: none;
    color: #4a4a4a;
    cursor: pointer;
    outline:none;
    padding: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    min-height: 12px;
    height: auto;
  }

  strong {
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-style: normal;
  }

  & > em {
    font-family: 'Proxima Nova Light Italic', sans-serif;
    font-style: normal;
  }
`
