import React, { useState, Suspense } from 'react'
import styled from 'styled-components'

import { StyledGlobalStyle } from '../../styles/styled-global-style'
import { FooterDataQuery, HeaderDataQuery } from '../../../gatsby-types'
import { ISEOContent } from '../../utils/seo-transformer'
import { getCookie } from '../../utils/cookies'

import Head from './head'

const Header = React.lazy(() => import('./header'))
const Main = React.lazy(() => import('./main'))
const Footer = React.lazy(() => import('./footer'))
const FooterHealthcare = React.lazy(() => import('./footer-healthcare'))

interface IProps {
  children?: React.ReactNode
  title: string
  path?: string
  footerPreviewData?: FooterDataQuery
  headerPreviewData?: HeaderDataQuery
  healthcare?: boolean
  seoContent?: ISEOContent
}

export default function Layout({
  title,
  headerPreviewData,
  footerPreviewData,
  children,
  path,
  healthcare,
  seoContent,
}: IProps) {
  const [showCookiesContainer, setShowCookiesContainer] = useState(getCookie('cookieSettings') !== 'true')

  return (
    <Wrapper id="wrapper">
      <Head seoContent={seoContent} title={title} />
      {typeof window !== 'undefined' && (
        <Suspense fallback={<div />}>
          <StyledGlobalStyle />
          <Header
            path={path}
            previewData={headerPreviewData}
            seoContent={seoContent}
            setShowCookiesContainer={setShowCookiesContainer}
            showCookiesContainer={showCookiesContainer}
          />
          <Main showCookiesContainer={showCookiesContainer}>{children}</Main>
          {healthcare ? <FooterHealthcare /> : <Footer previewData={footerPreviewData} />}
        </Suspense>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  background-color: #ffffff;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* overflow-x: hidden; */
  width: 100%;
`
