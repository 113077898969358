import React from 'react'
import { Helmet } from 'react-helmet'

// @ts-ignore
import coverImg from '../../assets/images/cover-just-a-game.jpg'
// @ts-ignore
// import favicon from '../../assets/images/favicon.ico'
// import favicon from '../../assets/images/favicon.png'
import { ISEOContent } from '../../utils/seo-transformer'

interface IProps {
  title?: React.ReactNode | string
  path?: string
  seoContent?: ISEOContent
}

export default function Head({ title, path, seoContent }: IProps) {
  // @ts-ignore
  const navigatorLanguage = 'fr'

  function getTitle() {
    if (seoContent && seoContent.title) return seoContent.title
    if (title) return title as string

    return 'My-Serious-Game'
  }
  function getOgTitle() {
    if (seoContent && seoContent.ogTitle) return seoContent.ogTitle
    if (title) return title as string

    return 'My-Serious-Game'
  }
  function getOgUrl() {
    if (seoContent && seoContent.ogUrl) return seoContent.ogUrl
    if (path) return path as string

    return 'https://my-serious-game.com'
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: navigatorLanguage,
      }}
    >
      <meta charSet="utf-8" />
      <title>{getTitle()}</title>
      <meta content={getTitle()} name="title" />
      <meta
        content={
          seoContent && seoContent.description
            ? seoContent.description
            : 'Leader Français dans la conception de formations digitales sur-mesure et haut de gamme. My-Serious-Game replace l’Homme au cœur du numérique et allie innovation, gamification et pédagogie.'
        }
        name="description"
      />
      <meta content={getOgTitle()} property="og:title" />
      <meta
        content={
          seoContent && seoContent.ogDescription
            ? seoContent.ogDescription
            : 'Leader Français dans la conception de formations digitales sur-mesure et haut de gamme. My-Serious-Game replace l’Homme au cœur du numérique et allie innovation, gamification et pédagogie.'
        }
        property="og:description"
      />
      <meta content={seoContent && seoContent.ogImage ? seoContent.ogImage : coverImg} property="og:image" />
      <meta content={getOgUrl()} property="og:url" />
      <meta
        content={seoContent && seoContent.ogSiteName ? seoContent.ogSiteName : 'My-Serious-game'}
        property="og:site_name"
      />
      <meta
        content={seoContent && seoContent.twitterCard ? seoContent.twitterCard : 'My-Serious-Game'}
        name="twitter:card"
      />
      <meta
        content={
          seoContent && seoContent.twitterTitle
            ? seoContent.twitterTitle
            : 'My-Serious-Game - Créateur de formations digitales sur-mesure'
        }
        name="twitter:title"
      />
      <meta
        content={
          seoContent && seoContent.twitterDescription
            ? seoContent.twitterDescription
            : 'Leader Français dans la conception de formations digitales sur-mesure et haut de gamme. My-Serious-Game replace l’Homme au cœur du numérique et allie innovation, gamification et pédagogie.'
        }
        name="twitter:description"
      />
      {seoContent && seoContent.twitterImage && <meta content={seoContent.twitterImage} property="twitter:image" />}
      {seoContent && seoContent.canonicalLink && <link href={seoContent.canonicalLink} rel="canonical" />}
      {/* <link href={favicon} rel="icon" sizes="16x16" type="image/png" /> */}
    </Helmet>
  )
}
