export function getCookie(cname: string): string {
  if (typeof window === 'undefined') return ''

  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(window.document.cookie)
  const ca = decodedCookie.split(';')
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.slice(1)
    }
    if (c.indexOf(name) === 0) {
      return c.slice(name.length, c.length)
    }
  }

  return ''
}

export function getYouTubeEmbed(embed: string): string {
  const withCookies = getCookie('enableCookieYouTube') === 'true'
  const splittedEmbed = embed.split('youtube')

  if (withCookies || !splittedEmbed[0] || !splittedEmbed[1]) return embed

  return `${splittedEmbed[0]}youtube-nocookie${splittedEmbed[1]}`
}
