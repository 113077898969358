import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

interface IProps {
  children: React.ReactNode
  color: 'primary' | 'secondary'
  disabled?: boolean
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  variant: 'contained' | 'outlined'
  width: string
  loading?: boolean
}

export default function Button({ disabled, color, variant, type, width, onClick, children, loading }: IProps) {
  return (
    <Container
      className={disabled ? 'disabled' : ''}
      color={color}
      disabled={disabled}
      onClick={onClick}
      type={type}
      variant={variant}
      width={width}
    >
      {loading ? <StyledCircularProgress color="primary" /> : children}
    </Container>
  )
}

const Container = styled.button<{ color: 'primary' | 'secondary'; variant: 'contained' | 'outlined'; width: string }>`
  align-items: center;
  background-color: ${({ color, variant, theme }) =>
    variant === 'contained' ? theme.palette[color].main : 'transparent'};
  border: ${({ color, variant, theme }) =>
    variant === 'contained' ? 'none' : `solid 2px ${theme.palette[color].main}`};
  border-radius: 4px;
  color: ${({ color, variant, theme }) =>
    variant === 'contained' ? theme.palette.text.secondary : theme.palette[color].main};
  display: flex;
  font-family: 'Proxima Nova Extrabold', sans-serif;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  text-transform: uppercase;
  width: ${({ width }) => width};

  &.disabled {
    background-color: ${({ color, variant, theme }) => (variant === 'contained' ? '#ccc' : 'transparent')};
    border: ${({ color, variant, theme }) => (variant === 'contained' ? 'none' : 'solid 2px #ccc')};
    color: ${({ color, variant, theme }) => (variant === 'contained' ? 'white' : '#ccc')};
    cursor: default;
  }

  &:not(.disabled):hover {
    opacity: 0.7;
  }
`

const StyledCircularProgress = styled(CircularProgress)`
  height: 25px !important;
  width: 25px !important;
`
