import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { IFooterCard } from '../../../types/custom'
import SvgArrowRight from '../../svg/arrow-right'

interface IProps {
  content: IFooterCard[]
}

export default function FooterCards({ content }: IProps) {
  return (
    <Container>
      {content &&
        content.map((elem, index) =>
          index < 2 ? (
            <SectionContainer background={elem.background.url} key={index} to={elem.link.url}>
              <div />
              <div />
              <h5>{elem.title}</h5>
              <h6>{elem.subtitle}</h6>
              <p>{elem.text}</p>
              <LinkContainer>
                <span>{elem.link.label}</span>
                <SvgArrowRight />
              </LinkContainer>
            </SectionContainer>
          ) : null
        )}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 64px;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    height: fit-content;
    padding: 16px;
  }
`

const SectionContainer = styled(Link)<{ background: string }>`
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 304px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 16px 48px;
  width: calc(50% - 8px);

  @media screen and (max-width: 768px) {
    backdrop-filter: blur(3px);
    height: 324px;
    margin-top: 64px;
    padding: 0 16px;
    width: 100%;
  }

  :hover {
    & > div:nth-child(1) {
      filter: blur(3px) grayscale(100%);
    }

    & > p {
      display: block;
    }

    & > div {
      display: flex;
    }
  }

  & > div:nth-child(1) {
    backdrop-filter: blur(3px);
    background: ${({ background }) => `url("${background}") center center`};
    background-size: cover;
    border-radius: 4px;
    height: 100%;
    filter: grayscale(100%);
    left: 0;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    transition: opacity 200ms ease-in;
    width: 100%;

    @media screen and (max-width: 768px) {
      filter: blur(3px) grayscale(100%);
    }
  }

  & > div:nth-child(2) {
    background-image: ${({ theme }) =>
      `linear-gradient(to bottom, ${theme.palette.secondary.main}cc, ${theme.palette.primary.main}cc)`};
    border-radius: 4px;
    height: 100%;
    left: 0;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    transition: opacity 200ms ease-in;
    width: 100%;
  }

  & > h5 {
    font-size: 12px;
    margin: 4px 0;
    position: relative;
    text-transform: uppercase;
  }

  & > h6 {
    font-family: 'Proxima Nova ExtraBold', sans-serif;
    font-size: 24px;
    margin: 4px 0;
    position: relative;
  }

  & > p {
    display: none;
    font-size: 16px;
    margin: 16px 0;
    position: relative;

    @media screen and (max-width: 480px) {
      display: block;
    }
  }
`

const LinkContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  font-family: 'Proxima Nova ExtraBold', sans-serif;
  font-size: 16px;
  position: relative;
  margin-top: 2px;

  @media screen and (max-width: 480px) {
    display: flex;
  }

  :hover {
    opacity: 0.7;
  }

  & > svg {
    height: 24px;
    margin-left: 8px;
    width: 24px;

    & > path {
      fill: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`
